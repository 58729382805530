<template>
  <Page title="Edit Shipment">
    <template>
      <v-row dense>
        <v-col>
          <v-autocomplete
            :items="filteredLabs"
            :filter="customFilter"
            :rules="rules.required"
            label="Select a Lab"
            no-data-text="No matches were found"
            item-text="title"
            item-value="id"
            :value="value"
            v-model="shipmentData.lab_id"
          >
            <template v-slot:label>
              <h4>Select a Lab</h4>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-autocomplete
            :items="allShippingMethods"
            :filter="customFilter"
            label="Select a Shipment Method"
            no-data-text="No matches were found"
            item-text="title"
            item-value="id"
            :value="value"
            @input="emitChange"
            v-model="shipmentData.shipment_method_id"
          >
            <template v-slot:label>
              <h4>Select a Shipment Method</h4>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="shipmentData.tracking_number"
            label="Tracking Number"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters dense>
        <v-col>
          <v-btn
            color="primary"
            class="float-right mb-1 ml-2"
            :disabled="!shipmentData.lab_id"
            @click="saveShipment"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template>
      <!-- <table
        style="margin-top: 20px; width: 100%; padding: 25px; border: 0.6px solid #D5D5D5; border-radius: 14px;"
      >
        <thead
          style="display: flex; justify-content: space-between; padding: 0 15px; margin-bottom: 7px;"
        >
          <th>Sample ID</th>
          <th>Feed Type</th>
          <th>Description</th>
          <th style="padding-right: 15px;">Status</th>
        </thead>
        <tbody style="text-align: right">
          <tr
            class="table-row"
            v-for="item in shipmentData.samples"
            :key="item.id"
            style="display: flex; justify-content: space-between; cursor: pointer; padding: 10px; border-top: 1px solid #979797; text-align: left;"
            @click="$router.push(`/samples/new-sample-wizard/${item.id}`)"
          >
            <td style="width: 160px;">{{ item.Slug }}</td>
            <td style="width: 150px;">{{ item.feed_type.title }}</td>
            <td style="width: 150px;">{{ item.description }}</td>
            <td>
              {{ item.is_received ? "Received" : "Not Received" }}
            </td>
          </tr>
        </tbody>
      </table> -->
      <v-data-table
        :headers="headers"
        :items="shipmentData.samples"
        item-key="id"
        @click:row="handleClick"
        style="margin-top: 20px; width: 100%; padding: 25px; border: 0.6px solid #D5D5D5; border-radius: 14px; cursor: pointer;"
      >
        <template v-slot:item.is_received="{ item }">
          <td
            v-for="head in headers.filter(h => h.value === 'is_received')"
            :key="head.text"
          >
            {{
              head.value === "is_received"
                ? item.is_received
                  ? "Received"
                  : "Not Received"
                : item[head.value]
            }}
          </td>
        </template>
      </v-data-table>
      <!-- <v-row>{{ shipmentData.samples }}</v-row> -->
    </template>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import dataTablePagination from "@/mixins/dataTablePagination";
import { mapGetters, mapActions } from "vuex";
import rules from "@/utils/rules";

export default {
  name: "ShipmentEdit",
  components: { Page },
  mixins: [dataTablePagination],
  data() {
    return {
      shipping_label: {
        active: false,
        item: false
      },
      shipping_status: {
        active: false,
        item: false
      },
      options: {
        params: {
          id: "",
          in_transit: this.$route.query.in_transit,
          client_id: ""
        }
      },
      rules: {
        required: [rules.required],
        alphanumericRequired: [rules.required, rules.alphanumeric],
        description: [rules.maxLength(60)]
      },
      size: {
        width: null,
        height: null,
        length: null,
        weight: null
      },
      shipmentData: null,
      loading: false,
      headers: [
        { text: "Sample ID", value: "Slug" },
        { text: "Feed Type", value: "feed_type.title" },
        { text: "Description", value: "description" },
        { text: "Status", value: "is_received" }
      ]
      // lab_id: this,
    };
  },
  computed: {
    ...mapGetters("baseData", {
      getById: "getById",
      allShippingMethods: "shipment_methods"
    }),
    ...mapGetters("baseData", ["labs"]),
    ...mapActions("baseData", ["getLabs"]),
    filteredLabs() {
      return this.labs.filter(lab => lab.is_active === true);
    },
    routeShipmentId() {
      return this.$route.params.shipmentId;
    }
  },
  methods: {
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1;
    },
    emitChange(value) {
      this.$emit("input", value);
    },
    ...mapActions("baseData", ["getShippingMethods"]),
    ...mapActions("shipments", [
      "epGetShipment",
      "epUpdateShipment",
      "editShipment",
      "getDropBoxCountries",
      "getDropBoxLocations",
      "getDropBoxLocation"
    ]),
    setError(message = "") {
      this.error.status = !!message.length;
      this.error.message = message;
    },
    setEasyPostItem(item) {
      this.ep_item = item;
      this.$emit("refresh", true);
      if (this.ep_item.parcel && this.ep_item.parcel.weight) {
        ["weight", "height", "width", "length"].map(item => {
          this.size[item] = this.ep_item.parcel[item];
        });
      }
      this.processShipmentResult();
    },
    loadShipment(skipValidate = false) {
      if (skipValidate || this.$refs.size.validate()) {
        this.loading = true;
        return this.epGetShipment({
          shipment: this.value,
          params: this.size
        }).then(data => {
          if (data === false) {
            this.setError("Request Error");
          } else {
            this.setEasyPostItem(data);
          }
          this.loading = false;
        });
      }
    },
    async saveShipment() {
      let promise = this.editShipment(this.shipmentData);
      promise.then(this.$router.push("/shipments/browse"));
    },
    ...mapActions("shipments", [
      "createShipment",
      "getShipment",
      "deleteShipment",
      "getUnshipped"
    ]),
    async fetchShipment() {
      this.shipmentData = await this.getShipment(this.routeShipmentId);
    },
    handleClick(row) {
      this.$router.push(`/samples/new-sample-edit/${row.id}`);
    }
  },
  beforeMount() {
    this.fetchShipment();
    console.log(this.shipmentData);
    this.lab_id = this.shipmentData.lab_id;
  },
  mounted() {
    Promise.all([this.getShippingMethods(), this.getLabs()]).then(
      () => (this.loading = false)
    );
  }
};
</script>

<style scoped></style>
