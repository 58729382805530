<template>
  <ShipmentEdit />
</template>

<script>
import ShipmentEdit from "@/components/shipments/ShipmentEdit";
export default {
  name: "EditShipments",
  components: { ShipmentEdit }
};
</script>

<style scoped></style>
